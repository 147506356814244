<template>
  <HighChartsResponsive :options="highchartOptions" ref="graph" class="highcharts-container" />
</template>

<script>
import { density1d } from 'fast-kde';
import moment from 'moment-timezone';
import round from 'lodash/round';

import HighChartsResponsive from '@/components/HighChartsResponsive.vue';

import { getWarrantySpans } from './helpers';

const graphFontSize = '14px';

export default {
  name: 'DistributionGraph',
  components: {
    HighChartsResponsive
  },
  props: {
    site: {
      type: Object
    },
    moduleDesign: {
      type: Object
    },
    modules: {
      type: Array
    },
    benchmarkCondition: {
      type: Object
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localLoading: false,
      highchartOptions: {
        chart: {
          type: 'areaspline'
        },
        title: {
          text: 'Distribution of STC Translated Pmp'
        },
        xAxis: {
          title: {
            text: 'Pmp (W)',
            style: {
              fontSize: graphFontSize
            }
          },
          labels: {
            style: {
              fontSize: graphFontSize
            }
          },
          showEmpty: false,
          plotLines: []
        },
        yAxis: {
          title: {
            text: ''
          },
          labels: {
            style: {
              fontSize: graphFontSize
            }
          },
          showEmpty: false
        },
        tooltip: {
          valueDecimals: 3
        },
        legend: {
          enabled: false
        },
        series: []
      }
    };
  },
  computed: {
    isLoading() {
      return this.loading || this.localLoading;
    },
    warrantySpans() {
      if (!this.site || !this.moduleDesign || !this.modules.length) return [];
      const [warrantyStartDate] = this.modules.filter(m => m.warrantyStartDate).map(m => new Date(m.warrantyStartDate)).sort();
      if (!warrantyStartDate) return [];
      return getWarrantySpans(this.moduleDesign.warranty || [], warrantyStartDate, this.site.timezone);
    },
    warrantyPmp() {
      if (!this.site || !this.moduleDesign || !this.benchmarkCondition) return null;
      const benchmarkConditionMoment = moment.tz(this.benchmarkCondition.timestamp, this.site.timezone);
      const span = this.warrantySpans.find(w => benchmarkConditionMoment >= w.spanStart && benchmarkConditionMoment <= w.spanEnd);
      if (!span) return null;
      return round(this.moduleDesign.nameplateStcPower * (span.f(benchmarkConditionMoment.valueOf()) / 100), 2);
    }
  },
  methods: {
    showLoading() {
      if (this.$refs.graph) this.$refs.graph.chart.showLoading();
    },
    hideLoading() {
      if (this.$refs.graph) this.$refs.graph.chart.hideLoading();
      if (this.$refs.graph && this.$refs.graph.chart && !this.highchartOptions.series.length) this.$refs.graph.chart.redraw();
    },
    clear() {
      this.$options.requestId = null;
      this.$set(this.highchartOptions, 'series', []);
      this.$set(this.highchartOptions.xAxis, 'softMin', null);
      this.$set(this.highchartOptions.xAxis, 'plotLines', []);
      if (this.$options.abortController) {
        const { abortController } = this.$options;
        this.$options.abortController = null;
        abortController.abort();
      }
    },
    async getStcTranslations() {
      try {
        this.clear();
        if (!this.site || !this.moduleDesign || !this.benchmarkCondition) return;

        this.$options.abortController = new this.$daqApi.AbortController();
        const { signal } = this.$options.abortController;
        const requestId = `${this.moduleDesign.uuid}_${this.benchmarkCondition.date}`;
        this.$options.requestId = requestId;

        this.localLoading = true;
        const { timestamp } = this.benchmarkCondition;
        const requests = this.modules.filter(m => m.poaSensorUuid).map((m) => {
          return this.$daqApi.post(`/sites/${this.site.id}/iv/translate`, { query: { moduleUuid: m.uuid }, body: [{ timestamp }], signal });
        });

        let data = await Promise.all(requests);
        data = data.flat().filter(d => d.data);
        if (this.$options.requestId !== requestId) return;
        this.$options.abortController = null;

        if (!data.length) {
          this.clear();
          return;
        }

        const d1 = density1d(data.map(d => d.data.Pmp), { bandwidth: 1 });
        const series = { id: `${this.moduleDesign.uuid}_d1`, name: 'Distribution', data: Array.from(d1), color: '#f15c80' };
        this.$set(this.highchartOptions, 'series', [series]);
        if (this.warrantyPmp != null) {
          this.$set(this.highchartOptions.xAxis, 'softMin', this.warrantyPmp);
          this.$set(this.highchartOptions.xAxis, 'plotLines', [{
            color: '#ff0000',
            width: 3,
            value: this.warrantyPmp,
            dashStyle: 'Dash',
            label: { text: `Aged Warranty Power - ${this.warrantyPmp} W`, x: 5, style: { fontSize: graphFontSize } },
            zIndex: 5
          }]);
        } else {
          this.$set(this.highchartOptions.xAxis, 'softMin', null);
          this.$set(this.highchartOptions.xAxis, 'plotLines', []);
        }
      } catch (e) {
        if (e.name === 'AbortError' || e.message.startsWith('AbortError') || e.message.startsWith('Aborted'));
        else if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        if (!this.$options.abortController) this.localLoading = false;
      }
    }
  },
  mounted() {
    this.getStcTranslations();
  },
  watch: {
    site() {
      this.getStcTranslations();
    },
    moduleDesign() {
      this.getStcTranslations();
    },
    modules() {
      this.getStcTranslations();
    },
    benchmarkCondition() {
      this.getStcTranslations();
    },
    isLoading() {
      if (this.isLoading) this.showLoading();
      else this.hideLoading();
    }
  }
};
</script>

<style lang="scss" scoped>
.highcharts-container {
  min-height: 0;
  min-width: 0;
}
</style>
