<template>
  <HighChartsResponsive :options="highchartOptions" ref="graph" class="highcharts-container" />
</template>

<script>
import min from 'lodash/min';
import max from 'lodash/max';
import HighChartsResponsive from '@/components/HighChartsResponsive.vue';

const graphFontSize = '14px';

export default {
  name: 'ThermalCoeffGraph',
  components: {
    HighChartsResponsive
  },
  props: {
    site: {
      type: Object
    },
    moduleDesign: {
      type: Object
    },
    modules: {
      type: Array
    },
    benchmarkConditions: {
      type: Array
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localLoading: false,
      highchartOptions: {
        title: {
          text: 'Voltage Thermal Coefficients'
        },
        yAxis: {
          title: {
            text: 'TC (%/C)',
            style: {
              fontSize: graphFontSize
            }
          },
          labels: {
            style: {
              fontSize: graphFontSize
            }
          },
          showEmpty: false,
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              fontSize: graphFontSize
            }
          },
          labels: {
            style: {
              fontSize: graphFontSize
            }
          },
          showEmpty: false,
          minRange: 900000
        },
        tooltip: {
          valueDecimals: 3,
          valueSuffix: ' %/C'
        },
        legend: {
          maxHeight: 40
        },
        series: []
      }
    };
  },
  computed: {
    isLoading() {
      return this.loading || this.localLoading;
    },
  },
  methods: {
    showLoading() {
      if (this.$refs.graph) this.$refs.graph.chart.showLoading();
    },
    hideLoading() {
      if (this.$refs.graph) this.$refs.graph.chart.hideLoading();
      if (this.$refs.graph && this.$refs.graph.chart && !this.highchartOptions.series.length) this.$refs.graph.chart.redraw();
    },
    clear() {
      this.$options.requestId = null;
      this.$set(this.highchartOptions, 'series', []);
      this.$set(this.highchartOptions.yAxis, 'softMin', null);
      this.$set(this.highchartOptions.yAxis, 'softMax', null);
      if (this.$options.abortController) {
        const { abortController } = this.$options;
        this.$options.abortController = null;
        abortController.abort();
      }
    },
    async getVoltageThermalCoefficients() {
      try {
        this.clear();
        if (!this.site || !this.moduleDesign || !this.benchmarkConditions.length) return;

        this.$options.abortController = new this.$daqApi.AbortController();
        const { signal } = this.$options.abortController;
        const requestId = `${this.moduleDesign.uuid}`;
        this.$options.requestId = requestId;

        this.localLoading = true;
        const modules = this.modules.filter(m => m.poaSensorUuid);

        const requests = modules.map(({ uuid: moduleUuid }) => {
          return this.$daqApi.post(`/sites/${this.site.id}/iv/voltage-thermal-coefficient`, {
            query: { moduleUuid },
            body: this.benchmarkConditions.map(c => c.timestamp),
            signal
          });
        });

        const data = await Promise.all(requests);
        if (this.$options.requestId !== requestId) return;
        this.$options.abortController = null;

        const series = data.map((moduleData, index) => {
          const module = modules[index];
          const id = module.uuid;
          moduleData = moduleData.filter(p => p.thermalCoefficientVoltage != null);
          return { id, name: module.name, data: moduleData.map(p => [(new Date(p.timestamp)).valueOf(), p.thermalCoefficientVoltage]) };
        }).filter(s => s.data.length > 1);

        this.$set(this.highchartOptions, 'series', series);

        const flatData = series.map(s => s.data).flat().map(p => p[1]);
        if (flatData.length) {
          const minCoef = min(flatData);
          const maxCoef = max(flatData);
          if (maxCoef - minCoef < 0.1) {
            const offset = (0.1 - (maxCoef - minCoef)) / 2;
            this.$set(this.highchartOptions.yAxis, 'softMin', minCoef - offset);
            this.$set(this.highchartOptions.yAxis, 'softMax', maxCoef + offset);
          }
        }
      } catch (e) {
        if (e.name === 'AbortError' || e.message.startsWith('AbortError') || e.message.startsWith('Aborted'));
        else if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        if (!this.$options.abortController) this.localLoading = false;
      }
    }
  },
  mounted() {
    if (this.site) this.$set(this.highchartOptions, 'time', { timezone: this.site.timezone });
    this.getVoltageThermalCoefficients();
  },
  watch: {
    site() {
      if (this.site) this.$set(this.highchartOptions, 'time', { timezone: this.site.timezone });
      this.getVoltageThermalCoefficients();
    },
    moduleDesign() {
      this.getVoltageThermalCoefficients();
    },
    modules() {
      this.getVoltageThermalCoefficients();
    },
    benchmarkConditions() {
      this.getVoltageThermalCoefficients();
    },
    isLoading() {
      if (this.isLoading) this.showLoading();
      else this.hideLoading();
    }
  }
};
</script>

<style lang="scss" scoped>
.highcharts-container {
  min-height: 0;
  min-width: 0;
}
</style>
