<template>
  <BTable
    class="stc-table mb-0"
    :class="{ 'stc-table-loading': isLoading }"
    striped
    hover
    :items="items"
    :fields="fields"
    borderless
    responsive
    :busy="isLoading"
    tbody-class="text-right"
    empty-text="No data to display"
    :show-empty="!isLoading"
    >

    <template #thead-top>
      <BTr>
        <BTh colspan="3" />
        <BTh
          variant="secondary"
          :colspan="modules.length + 1">
          Measured STC Translated
        </BTh>
      </BTr>
    </template>

    <template #table-busy>
      <div class="stc-table-loading-container">
        <div class="stc-table-loading-icon">
          <MsiSpinner :size="60" />
        </div>
      </div>
    </template>
  </BTable>
</template>

<script>
import { BTable, BTr, BTh } from 'bootstrap-vue';
import moment from 'moment-timezone';
import round from 'lodash/round';
import mean from 'lodash/mean';

import MsiSpinner from '@/components/MsiSpinner.vue';

import { getWarrantySpans } from './helpers';

function moduleParameterReducer(acc, cur, moduleTranslations, parameter) {
  if (moduleTranslations[cur.uuid] && moduleTranslations[cur.uuid][parameter] != null) {
    acc[cur.name] = moduleTranslations[cur.uuid][parameter];
  }

  return acc;
}

export default {
  name: 'StcTable',
  components: {
    BTable,
    BTr,
    BTh,
    MsiSpinner
  },
  props: {
    site: {
      type: Object
    },
    moduleDesign: {
      type: Object
    },
    modules: {
      type: Array
    },
    benchmarkCondition: {
      type: Object
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      moduleTranslations: {},
      localLoading: false
    };
  },
  computed: {
    isLoading() {
      return this.loading || this.localLoading;
    },
    fields() {
      const staticFields = [
        { key: 'parameter', label: '' },
        { key: 'datasheet', label: 'Datasheet' },
        { key: 'expected', label: 'Expected' },
        { key: 'mean', label: 'Mean' }
      ];

      const dynamicFields = this.modules.map(m => ({ key: m.name, label: m.name }));
      return [...staticFields, ...dynamicFields];
    },
    items() {
      if (!this.site || !this.moduleDesign || !this.modules.length) return [];
      const { nameplateStcPower, nameplateImp, nameplateIsc, nameplateVmp, nameplateVoc, thermalCoefficientVoltage } = this.moduleDesign;
      const pmp = this.modules.reduce((acc, cur) => moduleParameterReducer(acc, cur, this.moduleTranslations, 'Pmp'), {});
      const voc = this.modules.reduce((acc, cur) => moduleParameterReducer(acc, cur, this.moduleTranslations, 'Voc'), {});
      const isc = this.modules.reduce((acc, cur) => moduleParameterReducer(acc, cur, this.moduleTranslations, 'Isc'), {});
      const vmp = this.modules.reduce((acc, cur) => moduleParameterReducer(acc, cur, this.moduleTranslations, 'Vmp'), {});
      const imp = this.modules.reduce((acc, cur) => moduleParameterReducer(acc, cur, this.moduleTranslations, 'Imp'), {});
      const coeff = this.modules.reduce((acc, cur) => moduleParameterReducer(acc, cur, this.moduleTranslations, 'thermalCoefficientVoltage'), {});
      return [
        { parameter: 'Pmp [W]', datasheet: nameplateStcPower, expected: this.warrantyPmp, mean: round(mean(Object.values(pmp)), 2) || null, ...pmp },
        { parameter: 'Voc [V]', datasheet: nameplateVoc, mean: round(mean(Object.values(voc)), 2) || null, ...voc },
        { parameter: 'Isc [A]', datasheet: nameplateIsc, mean: round(mean(Object.values(isc)), 2) || null, ...isc },
        { parameter: 'Vmp [V]', datasheet: nameplateVmp, mean: round(mean(Object.values(vmp)), 2) || null, ...vmp },
        { parameter: 'Imp [A]', datasheet: nameplateImp, mean: round(mean(Object.values(imp)), 2) || null, ...imp },
        { parameter: 'Temp. Coeff. [%/C]', datasheet: thermalCoefficientVoltage, mean: round(mean(Object.values(coeff)), 3) || null, ...coeff }
      ];
    },
    warrantySpans() {
      if (!this.site || !this.moduleDesign || !this.modules.length) return [];
      const [warrantyStartDate] = this.modules.filter(m => m.warrantyStartDate).map(m => new Date(m.warrantyStartDate)).sort();
      if (!warrantyStartDate) return [];
      return getWarrantySpans(this.moduleDesign.warranty || [], warrantyStartDate, this.site.timezone);
    },
    warrantyPmp() {
      if (!this.site || !this.moduleDesign || !this.benchmarkCondition) return null;
      const benchmarkConditionMoment = moment.tz(this.benchmarkCondition.timestamp, this.site.timezone);
      const span = this.warrantySpans.find(w => benchmarkConditionMoment >= w.spanStart && benchmarkConditionMoment <= w.spanEnd);
      if (!span) return null;
      return round(this.moduleDesign.nameplateStcPower * (span.f(benchmarkConditionMoment.valueOf()) / 100), 2);
    }
  },
  methods: {
    clear() {
      this.$options.requestId = null;
      this.moduleTranslations = {};
      if (this.$options.abortController) {
        const { abortController } = this.$options;
        this.$options.abortController = null;
        abortController.abort();
      }
    },
    async getStcTranslations() {
      try {
        this.clear();
        if (!this.site || !this.moduleDesign || !this.benchmarkCondition) return;

        this.$options.abortController = new this.$daqApi.AbortController();
        const { signal } = this.$options.abortController;
        const requestId = `${this.moduleDesign.uuid}_${this.benchmarkCondition.date}`;
        this.$options.requestId = requestId;

        this.localLoading = true;
        const { timestamp } = this.benchmarkCondition;
        const modules = this.modules.filter(m => m.poaSensorUuid);

        const translationRequests = modules.map((m) => {
          const moduleUuid = m.uuid;
          return this.$daqApi.post(`/sites/${this.site.id}/iv/translate`, { query: { moduleUuid }, body: [{ timestamp }], signal });
        });

        const thermalCoefficientRequests = modules.map((m) => {
          const moduleUuid = m.uuid;
          return this.$daqApi.post(`/sites/${this.site.id}/iv/voltage-thermal-coefficient`, { query: { moduleUuid }, body: [timestamp], signal });
        });

        const [translationData, thermalCoefficientData] = await Promise.all([
          Promise.all(translationRequests),
          Promise.all(thermalCoefficientRequests)
        ]);

        if (this.$options.requestId !== requestId) return;
        this.$options.abortController = null;

        this.moduleTranslations = translationData.reduce((acc, cur, index) => {
          const thermalCoefficientVoltage = round(thermalCoefficientData[index][0].thermalCoefficientVoltage, 3) || null;
          acc[modules[index].uuid] = { ...cur[0].data, thermalCoefficientVoltage };
          return acc;
        }, {});
      } catch (e) {
        if (e.name === 'AbortError' || e.message.startsWith('AbortError') || e.message.startsWith('Aborted'));
        else if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        if (!this.$options.abortController) this.localLoading = false;
      }
    }
  },
  mounted() {
    this.getStcTranslations();
  },
  watch: {
    site() {
      this.getStcTranslations();
    },
    moduleDesign() {
      this.getStcTranslations();
    },
    modules() {
      this.getStcTranslations();
    },
    benchmarkCondition() {
      this.getStcTranslations();
    }
  }
};
</script>

<style lang="scss" scoped>
.stc-table {
  white-space: nowrap;
  min-height: 360px;
}

.stc-table-loading {
  overflow-x: hidden;
}

.stc-table-loading-container {
  height: 60px;
}

.stc-table-loading-icon {
  left: 50%;
  position: absolute;
  transform: translateX(-30px);
}
</style>
