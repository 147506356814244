<template>
  <div>
    <div class="graph-container flex-grow-1 flex-shrink-1 d-flex flex-column">
      <HighChartsResponsive :options="stcGraphOptions" ref="stcGraph" class="highcharts-container" />
    </div>

    <div class="graph-container flex-grow-1 flex-shrink-1 d-flex flex-column">
      <BFormCheckbox
        class="chart-toggle"
        switch
        v-model="srcNormalizedChecked"
        :disabled="isLoading"
      >
        {{ srcNormalizedChecked ? 'Normalized' : 'Pmp SRC' }}
      </BFormCheckbox>

      <HighChartsResponsive :options="srcGraphOptions" ref="srcGraph" class="highcharts-container" />
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import round from 'lodash/round';
import { BFormCheckbox } from 'bootstrap-vue';

import HighChartsResponsive from '@/components/HighChartsResponsive.vue';

import { getWarrantySpans } from './helpers';

const graphFontSize = '14px';

export default {
  name: 'StcGraphs',
  components: {
    HighChartsResponsive,
    BFormCheckbox
  },
  props: {
    site: {
      type: Object
    },
    moduleDesign: {
      type: Object
    },
    modules: {
      type: Array
    },
    benchmarkConditions: {
      type: Array
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      srcNormalizedChecked: true,
      localLoading: false,
      stcGraphOptions: {
        title: {
          text: 'STC Translated Pmp'
        },
        yAxis: {
          title: {
            text: 'Pmp (W)',
            style: {
              fontSize: graphFontSize
            }
          },
          labels: {
            style: {
              fontSize: graphFontSize
            }
          },
          showEmpty: false
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              fontSize: graphFontSize
            }
          },
          labels: {
            style: {
              fontSize: graphFontSize
            }
          },
          showEmpty: false,
          minRange: 900000
        },
        legend: {
          maxHeight: 40
        },
        tooltip: {
          valueSuffix: ' W'
        },
        series: []
      },
      srcGraphOptions: {
        title: {
          text: 'SRC Performance'
        },
        yAxis: {
          title: {
            text: 'Actual/Expected',
            style: {
              fontSize: graphFontSize
            }
          },
          labels: {
            style: {
              fontSize: graphFontSize
            }
          },
          showEmpty: false
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              fontSize: graphFontSize
            }
          },
          labels: {
            style: {
              fontSize: graphFontSize
            }
          },
          showEmpty: false,
          minRange: 900000
        },
        legend: {
          maxHeight: 40
        },
        tooltip: {
          formatter: function formatter(tooltip) {
            const date = tooltip.defaultFormatter.call(this, tooltip)[0];
            let content = `${date}<span style="color: ${this.color}">\u25CF</span> ${this.series.name}: `;
            if (this.series.options.metaData && this.series.options.metaData.srcNormalizedChecked) {
              content += `<b>${round(this.point.y, 2)}</b><br/>
              <b>Pmp SRC: </b>${round(this.point.options.srcPmp, 2)} W<br/>
              <b>Nameplate Pmp SRC: </b>${round(this.point.options.srcNameplatePmp, 2)} W`;
            } else {
              content += `<b>${round(this.point.y, 2)} W</b>`;
            }

            return content;
          },
          useHTML: true
        },
        series: [],
      }
    };
  },
  computed: {
    isLoading() {
      return this.loading || this.localLoading;
    },
  },
  methods: {
    showLoading() {
      if (this.$refs.stcGraph) this.$refs.stcGraph.chart.showLoading();
      if (this.$refs.srcGraph) this.$refs.srcGraph.chart.showLoading();
    },
    hideLoading() {
      if (this.$refs.stcGraph) this.$refs.stcGraph.chart.hideLoading();
      if (this.$refs.srcGraph) this.$refs.srcGraph.chart.hideLoading();
      if (this.$refs.stcGraph && this.$refs.stcGraph.chart && !this.stcGraphOptions.series.length) this.$refs.stcGraph.chart.redraw();
      if (this.$refs.srcGraph && this.$refs.srcGraph.chart && !this.srcGraphOptions.series.length) this.$refs.srcGraph.chart.redraw();
    },
    clear() {
      this.$options.requestId = null;
      this.$options.modules = [];
      this.$options.stcData = [];
      this.$options.srcData = [];
      this.$set(this.stcGraphOptions, 'series', []);
      this.$set(this.srcGraphOptions, 'series', []);
      if (this.$options.abortController) {
        const { abortController } = this.$options;
        this.$options.abortController = null;
        abortController.abort();
      }
    },
    async getStcTranslations() {
      try {
        this.clear();
        if (!this.site || !this.moduleDesign || !this.benchmarkConditions.length) return;

        this.$options.abortController = new this.$daqApi.AbortController();
        const { signal } = this.$options.abortController;
        const requestId = this.moduleDesign.uuid;
        this.$options.requestId = requestId;

        this.localLoading = true;
        const modules = this.modules.filter(m => m.poaSensorUuid);

        const stcRequests = modules.map(({ uuid: moduleUuid }) => {
          const body = this.benchmarkConditions.map(c => ({ timestamp: c.timestamp }));
          return this.$daqApi.post(`/sites/${this.site.id}/iv/translate`, { query: { moduleUuid }, body, signal });
        });

        const srcRequests = modules.map(({ uuid: moduleUuid }) => {
          const body = this.benchmarkConditions.map(c => ({ timestamp: c.timestamp, targetIrr: c.irradiance, targetTemp: c.cellTemperature }));
          return this.$daqApi.post(`/sites/${this.site.id}/iv/translate`, { query: { moduleUuid }, body, signal });
        });

        const [stcData, srcData] = await Promise.all([Promise.all(stcRequests), Promise.all(srcRequests)]);
        if (this.$options.requestId !== requestId) return;
        this.$options.abortController = null;

        this.$options.modules = modules;
        this.$options.stcData = stcData;
        this.$options.srcData = srcData;

        const stcSeries = this.getStcSeries(modules);
        const warrantySeries = this.getWarrantySeries();
        if (warrantySeries.data.length) stcSeries.push(warrantySeries);
        this.plotStc(stcSeries);

        const srcSeries = this.getSrcSeries(modules);
        this.plotSrc(srcSeries);
      } catch (e) {
        if (e.name === 'AbortError' || e.message.startsWith('AbortError') || e.message.startsWith('Aborted'));
        else if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        if (!this.$options.abortController) this.localLoading = false;
      }
    },
    getStcSeries() {
      return this.$options.stcData.map((moduleData, index) => {
        const module = this.$options.modules[index];
        const id = module.uuid;
        moduleData = moduleData.filter(d => d.data);
        return { id, name: module.name, data: moduleData.map(p => [(new Date(p.timestamp)).valueOf(), p.data.Pmp]) };
      }).filter(s => s.data.length > 1);
    },
    getSrcSeries() {
      const { srcNormalizedChecked } = this;
      return this.$options.srcData.map((moduleData, index) => {
        const module = this.$options.modules[index];
        const id = module.uuid;
        moduleData = moduleData.filter(d => d.data);

        let data = [];
        if (srcNormalizedChecked) {
          data = moduleData.map((p) => {
            const options = { srcPmp: p.data.Pmp, srcNameplatePmp: p.data.ExpectedPmp };
            return { x: (new Date(p.timestamp)).valueOf(), y: round(p.data.Pmp / p.data.ExpectedPmp, 2), options };
          });
        } else {
          data = moduleData.map(p => [(new Date(p.timestamp)).valueOf(), round(p.data.Pmp, 2)]);
        }

        return { id, name: module.name, data, metaData: { srcNormalizedChecked } };
      }).filter(s => s.data.length > 1);
    },
    getWarrantySeries() {
      const [warrantyStartDate] = this.$options.modules.filter(m => m.warrantyStartDate).map(m => new Date(m.warrantyStartDate)).sort();
      const warrantySpans = warrantyStartDate ? getWarrantySpans(this.moduleDesign.warranty, warrantyStartDate, this.site.timezone) : [];
      const warrantyData = this.benchmarkConditions.map((c) => {
        const conditionMoment = moment.tz(c.timestamp, this.site.timezone);
        const span = warrantySpans.find(w => conditionMoment >= w.spanStart && conditionMoment <= w.spanEnd);
        if (!span) return null;
        return [conditionMoment.valueOf(), round(this.moduleDesign.nameplateStcPower * (span.f(conditionMoment.valueOf()) / 100), 2)];
      }).filter(p => !!p);

      return { id: this.moduleDesign.uuid, name: 'Warranty', data: warrantyData, dashStyle: 'Dash' };
    },
    plotStc(series) {
      this.$set(this.stcGraphOptions, 'series', series);
    },
    plotSrc(series) {
      this.$set(this.srcGraphOptions, 'series', series);
      if (this.srcNormalizedChecked) {
        this.$set(this.srcGraphOptions.yAxis.title, 'text', 'Actual/Expected');
      } else {
        this.$set(this.srcGraphOptions.yAxis.title, 'text', 'Pmp SRC (W)');
      }
    }
  },
  mounted() {
    if (this.site) {
      this.$set(this.stcGraphOptions, 'time', { timezone: this.site.timezone });
      this.$set(this.srcGraphOptions, 'time', { timezone: this.site.timezone });
    }

    this.getStcTranslations();
  },
  watch: {
    site() {
      if (this.site) {
        this.$set(this.stcGraphOptions, 'time', { timezone: this.site.timezone });
        this.$set(this.srcGraphOptions, 'time', { timezone: this.site.timezone });
      }

      this.getStcTranslations();
    },
    moduleDesign() {
      this.getStcTranslations();
    },
    modules() {
      this.getStcTranslations();
    },
    benchmarkConditions() {
      this.getStcTranslations();
    },
    isLoading() {
      if (this.isLoading) this.showLoading();
      else this.hideLoading();
    },
    srcNormalizedChecked() {
      this.plotSrc(this.getSrcSeries());
    }
  }
};
</script>

<style lang="scss" scoped>
.graph-container {
  flex-basis: 0;
  min-height: 300px;
  position: relative;
}
.highcharts-container {
  min-height: 0;
  min-width: 0;
}

.chart-toggle {
  position: absolute;
  z-index: 100;
  left: 8px;
  top: 4px;
  font-size: 15px;
}
</style>
