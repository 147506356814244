<template>
  <div class="module-health-container d-flex flex-column">
    <BNavbar
      class="module-health-navbar shadow-sm border-bottom">
      <BFormGroup
        class="mb-0"
        label="Module Design"
        label-cols="auto">
        <Multiselect
          class="multiselect mr-3"
          v-model="selectedModuleDesign"
          :options="moduleDesigns"
          selectLabel=""
          deselectLabel=""
          placeholder="Module Design"
          closeOnSelect
          :disabled="loading"
          trackBy="name"
          label="name"
          :allowEmpty="false" />
      </BFormGroup>
    </BNavbar>

    <BContainer
      fluid
      class="m-0 px-2 px-md-3 pt-2 pt-md-3 flex-grow-1 d-flex flex-column min-height-fit">

      <BCard
        class="mb-2 mb-lg-3 min-height-fit"
        body-class="p-0 min-height-fit">
        <BFormGroup
          class="mb-0 ml-2 mt-2 mb-2"
          label="Benchmark Date"
          label-cols="auto">
          <Multiselect
            class="multiselect"
            v-model="selectedBenchmarkCondition"
            :options="benchmarkConditions"
            selectLabel=""
            deselectLabel=""
            placeholder="Benchmark Date"
            closeOnSelect
            :disabled="loading"
            trackBy="date"
            label="date"
            :allowEmpty="false" />
        </BFormGroup>

        <BRow class="min-height-fit" no-gutters>
          <BCol cols="12" lg="8" class="min-height-fit pr-0 pr-lg-2">
            <StcTable
              :site="selectedSite"
              :moduleDesign="selectedModuleDesignDetails"
              :modules="moduleDesignModules"
              :benchmarkCondition="selectedBenchmarkCondition"
              :loading="loading"
            />
          </BCol>

          <BCol cols="12" lg="4" class="min-height-fit d-flex flex-column pl-0 pl-lg-2">
            <div class="graph-container flex-grow-1 flex-shrink-1 d-flex flex-column">
              <DistributionGraph
                :site="selectedSite"
                :moduleDesign="selectedModuleDesignDetails"
                :modules="moduleDesignModules"
                :benchmarkCondition="selectedBenchmarkCondition"
                :loading="loading"
              />
            </div>
          </BCol>
        </BRow>
      </BCard>

      <BRow class="min-height-fit flex-grow-1" no-gutters>
        <BCol cols="12" lg="8" class="min-height-fit d-flex flex-column pr-0 pr-lg-2">
          <BCard
            class="min-height-fit flex-grow-1 d-flex flex-column mb-2 mb-lg-3"
            body-class="min-height-fit flex-grow-1 d-flex flex-column p-2">
              <StcGraphs
                class="min-height-fit flex-grow-1 d-flex flex-column"
                :site="selectedSite"
                :moduleDesign="selectedModuleDesignDetails"
                :modules="moduleDesignModules"
                :benchmarkConditions="benchmarkConditions"
                :loading="loading"
              />
          </BCard>
        </BCol>

        <BCol cols="12" lg="4" class="min-height-fit d-flex flex-column pl-0 pl-lg-2">
          <BCard
            class="min-height-fit flex-grow-1 d-flex flex-column mb-2 mb-md-3"
            body-class="min-height-fit flex-grow-1 d-flex flex-column p-2">
            <div class="graph-container flex-grow-1 flex-shrink-1 d-flex flex-column">
              <ThermalCoeffGraph
                :site="selectedSite"
                :moduleDesign="selectedModuleDesignDetails"
                :modules="moduleDesignModules"
                :benchmarkConditions="benchmarkConditions"
                :loading="loading"
              />
            </div>
          </BCard>
        </BCol>
      </BRow>
    </BContainer>
  </div>
</template>

<script>
import { BContainer, BRow, BCol, BCard, BNavbar, BFormGroup } from 'bootstrap-vue';
import { get } from 'vuex-pathify';
import Multiselect from 'vue-multiselect';
import moment from 'moment-timezone';

import StcTable from '@/components/module-health/StcTable.vue';
import DistributionGraph from '@/components/module-health/DistributionGraph.vue';
import StcGraphs from '@/components/module-health/StcGraphs.vue';
import ThermalCoeffGraph from '@/components/module-health/ThermalCoeffGraph.vue';

const requiredDefaultMetric = 'POA_SILICON';

export default {
  name: 'ModuleHealth',
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BNavbar,
    BFormGroup,
    Multiselect,
    StcTable,
    DistributionGraph,
    StcGraphs,
    ThermalCoeffGraph
  },
  data() {
    return {
      selectedModuleDesign: null,
      selectedModuleDesignDetails: null,
      moduleDesignDetailsLoading: false,
      benchmarkConditions: [],
      selectedBenchmarkCondition: null,
      benchmarkConditionsLoading: false
    };
  },
  computed: {
    selectedSite: get('sites/selectedSite'),
    getSiteSolarModules: get('solarmodules/getSiteSolarModules'),
    getSiteModuleDesigns: get('moduleDesigns/getSiteModuleDesigns'),
    getSiteMetrics: get('metrics/getSiteMetrics'),
    modules() {
      if (!this.selectedSite) return [];
      return this.getSiteSolarModules(this.selectedSite.id);
    },
    metrics() {
      if (!this.selectedSite) return [];
      return this.getSiteMetrics(this.selectedSite.id);
    },
    modulesWithDefaultSensor() {
      return this.modules
        .filter(m => m.defaultMetrics && m.defaultMetrics[requiredDefaultMetric])
        .map((m) => {
          const metric = this.metrics.find(mt => mt.uuid === m.defaultMetrics[requiredDefaultMetric].metricUuid);
          return { ...m, poaSensorUuid: metric && metric.config && metric.config.sensorUuid };
        });
    },
    moduleDesigns() {
      if (!this.selectedSite) return [];
      const moduleDesigns = this.getSiteModuleDesigns(this.selectedSite.id);
      return moduleDesigns.filter(md => this.modulesWithDefaultSensor.find(m => m.moduleDesignUuid === md.uuid));
    },
    moduleDesignModules() {
      if (!this.selectedModuleDesign) return [];
      return this.modulesWithDefaultSensor.filter(m => m.moduleDesignUuid === this.selectedModuleDesign.uuid);
    },
    loading() {
      return this.benchmarkConditionsLoading || this.moduleDesignDetailsLoading;
    }
  },
  methods: {
    async getBenchmarkConditions() {
      if (!this.selectedSite) return;
      const requestId = this.selectedSite.id;
      this.$options.getBenchmarkConditionsRequestId = requestId;

      try {
        this.benchmarkConditionsLoading = true;
        const benchmarkConditions = await this.$daqApi.get(`/sites/${this.selectedSite.id}/benchmark-conditions`);
        if (requestId !== this.$options.getBenchmarkConditionsRequestId) return;
        const { timezone } = this.selectedSite;
        this.benchmarkConditions = benchmarkConditions.map(c => ({ ...c, date: moment(c.timestamp).tz(timezone).format('YYYY-MM-DD') }));
        if (this.benchmarkConditions.length) this.selectedBenchmarkCondition = this.benchmarkConditions[this.benchmarkConditions.length - 1];
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.benchmarkConditionsLoading = false;
      }
    },
    async getModuleDesignDetails() {
      if (!this.selectedSite || !this.selectedModuleDesign) return;
      const requestId = `${this.selectedSite.id}_${this.selectedModuleDesign.uuid}`;
      this.$options.getModuleDesignDetailsRequestId = requestId;

      try {
        this.moduleDesignDetailsLoading = true;
        const moduleDesignDetails = await this.$daqApi.get(`/sites/${this.selectedSite.id}/module-designs/${this.selectedModuleDesign.uuid}`);
        if (requestId !== this.$options.getModuleDesignDetailsRequestId) return;
        this.selectedModuleDesignDetails = moduleDesignDetails;
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.moduleDesignDetailsLoading = false;
      }
    }
  },
  mounted() {
    this.getBenchmarkConditions();
    if (this.moduleDesigns.length) [this.selectedModuleDesign] = this.moduleDesigns;
    this.getModuleDesignDetails();
  },
  watch: {
    selectedSite() {
      this.selectedModuleDesign = null;
      this.benchmarkConditions = [];
      this.selectedBenchmarkCondition = null;
      this.getBenchmarkConditions();
    },
    moduleDesigns() {
      if (this.moduleDesigns.length) [this.selectedModuleDesign] = this.moduleDesigns;
    },
    selectedModuleDesign() {
      this.selectedModuleDesignDetails = null;
      this.getModuleDesignDetails();
    }
  }
};
</script>

<style lang="scss" scoped>
.module-health-container {
  height: calc(100vh - 105px)
}
.module-health-navbar {
  background-color: #fcfcfc;
}
.multiselect {
  width: 250px;
}

.min-height-fit {
  min-height: fit-content;
}

.graph-container {
  flex-basis: 0;
  min-height: 315px;
}
</style>
