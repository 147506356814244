/* eslint-disable import/prefer-default-export */
import moment from 'moment-timezone';

function getWarrantySpans(warranty, warrantyStartDate, timezone = 'UTC') {
  let t = 0;
  const warrantyStartDateMoment = moment(warrantyStartDate).tz(timezone);
  return warranty.map(({ span, start, end }) => {
    const spanStart = moment(warrantyStartDateMoment).add(t, 'years');
    const spanEnd = moment(warrantyStartDateMoment).add(t + span, 'years');
    t += span;
    return { spanStart, spanEnd, f: x => ((end - start) / (spanEnd.valueOf() - spanStart.valueOf())) * (x - spanStart.valueOf()) + start };
  });
}

export {
  getWarrantySpans
};
